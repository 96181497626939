/* eslint-disable max-lines */
export const populateUserGroups = `mutation PopulateUserGroups {
  populateUserGroups
}
`;

export const createFolder = `mutation CreateFolder($folderName: String!, $parentId: String, $clonePermission: Boolean, $isPublic: Boolean, $footerContent: String, $folderAlias: String) {
  createFolder(
    folderName: $folderName,
    parentId: $parentId,
    clonePermission: $clonePermission
    isPublic: $isPublic,
    footerContent: $footerContent,
    folderAlias: $folderAlias,
  ) {
    id
  }
}`;

export const deleteFolder = `mutation deleteFolder($folderId: String!) {
  deleteFolder(folderId: $folderId)
}
`;

export const deleteDocument = `mutation deleteDocument($documentId: String!, $supplementalTag: String, $retractedComment: String, $retractedBy: String) {
  deleteDocument(documentId: $documentId, supplementalTag: $supplementalTag, retractedComment: $retractedComment, retractedBy:$retractedBy )
}
`;

export const setDocumentActiveStatus = `mutation setDocumentActiveStatus($documentId: String!, $documentStatus: String, $retractedComment: String, $supplementalTag: String, $translationTag: String) {
  setDocumentActiveStatus(documentId: $documentId, documentStatus: $documentStatus, retractedComment: $retractedComment, supplementalTag: $supplementalTag,translationTag: $translationTag)
}
`;

export const revokeException = `mutation revokeException($documentId: String!, $exceptionTitle:String!, $revokeComment: String!, $policyName: String!, $policyLink: String!, $approvalDate: String!) {
  revokeException(documentId: $documentId, exceptionTitle: $exceptionTitle, revokeComment: $revokeComment, policyName: $policyName, policyLink: $policyLink, approvalDate: $approvalDate)
}
`;

export const deleteRole = `mutation deleteRole($roleId: String!, $entityId: String!, $entityType: EntityType!) {
  deleteRole(
    roleId: $roleId,
    entityId: $entityId,
    entityType: $entityType
  )
}`;


export const updateFolder = `mutation updateFolder($folderId: String!, $folderName: String!, $parentId: String, $isPublic: Boolean, $isRelated: Boolean, $footerContent: String, $folderAlias: String) {
  updateFolder(
    folderId: $folderId,
    folderName: $folderName,
    parentId: $parentId,
    isPublic: $isPublic,
    isRelated: $isRelated,
    folderAlias: $folderAlias,
    footerContent: $footerContent) {
      id
    }
} `;

export const createRole = `mutation CreateRole($roleName: String!, $actions: AWSJSON!, $entityId: String!, $entityType: EntityType!) {
  createRole(
    roleName: $roleName,
    actions: $actions,
    entityType: $entityType,
    entityId: $entityId
  ) {
    id
    roleName
    actions
    users {
    userAlias
    }
  }
}`;

export const addUserToRole = `mutation AddUserToRole($roleId: String!, $userAlias: String!, $entityId: String!, $entityType: EntityType!, $inherit: Boolean) {
  addUserToRole(
    roleId: $roleId,
    userAlias: $userAlias,
    entityId: $entityId,
    entityType: $entityType,
    inherit: $inherit
  )
}`;

export const createActionItem = `mutation createActionItem($createdBy: String!, $status: String!, $name: String!, $description: String!, $dueDate: String!, $owners: [UserList]!, $priority: String!, $attachments: [ActionItemAttachmentsLinks], $links:[ActionItemAttachmentsLinks], $approvers: [UserList]!,
  $exceptionId: String!, $exceptionTitle: String!, $isOpen: Boolean, $documentType: String) {
  createActionItem(
    createdBy: $createdBy,
    name: $name,
    description: $description,
    dueDate: $dueDate,
    owners: $owners,
    priority: $priority,
    attachments: $attachments,
    links: $links,
    approvers: $approvers,
    status: $status,
    exceptionId: $exceptionId,
    exceptionTitle: $exceptionTitle,
    isOpen: $isOpen,
    documentType: $documentType
  )
}`;

export const createActionItemInstances = `mutation createActionItemInstances($entityIdList: [String]!, $parentId: String!) {
  createActionItemInstances(
    entityIdList: $entityIdList,
    parentId: $parentId
)
}`;


export const deleteActionItem = `mutation deleteActionItem($entityId: String!, $parentId: String!) {
    deleteActionItem(
      entityId: $entityId
      parentId: $parentId
  )
}`;


export const updateActionItem = `mutation updateActionItem($entityId: String!, $createdBy: String!, $status: String!, $name: String!, $description: String!, $dueDate: String!, $owners: [UserList]!, $priority: String!, $attachments: [ActionItemAttachmentsLinks], $links:[ActionItemAttachmentsLinks], $approvers: [UserList]!,
  $exceptionId: String!, $exceptionTitle: String!, $workflowTemplateId: String!, $workflowInstanceId: String, $isOpen: Boolean, $documentType: String) {
  updateActionItem(
    entityId: $entityId,
    createdBy: $createdBy,
    name: $name,
    description: $description,
    dueDate: $dueDate,
    owners: $owners,
    priority: $priority,
    attachments: $attachments,
    links: $links,
    approvers: $approvers,
    status: $status,
    exceptionId: $exceptionId,
    exceptionTitle: $exceptionTitle,
    workflowInstanceId: $workflowInstanceId,
    workflowTemplateId: $workflowTemplateId,
    isOpen: $isOpen,
    documentType: $documentType
  )
}`;

export const getActionItemAttachmentsUrl = `mutation getActionItemAttachmentsUrl($createdBy: String!, $exceptionId: String!, $attachments: [ActionItemAttachments]!) {
  getActionItemAttachmentsUrl(
    createdBy: $createdBy,
    exceptionId: $exceptionId,
    attachments: $attachments
)
}`;


export const updateRole = `mutation UpdateRole($roleId: String!, $actions: AWSJSON!, $roleName: String!, $entityId: String!, $entityType: EntityType!) {
  updateRole(
    roleId: $roleId,
    actions: $actions,
    roleName: $roleName,
    entityId: $entityId,
    entityType: $entityType,
  )
}`;

export const removeUserFromRole = `mutation RemoveUserFromRole($entityId: String!, $roleId: String!, $userAlias: String!, $entityType: EntityType!) {
  removeUserFromRole(
    entityId: $entityId,
    userAlias: $userAlias,
    entityType: $entityType,
    roleId: $roleId,
  )
}`;

export const updateUserRole = `mutation UpdateUserRole($userAlias: String!, $roleId: String!, $entityId: String!, $entityId: String!, $entityType: EntityType!, $currentRoleId: String!, $inherit: Boolean) {
  updateUserRole(
    userAlias: $userAlias,
    roleId: $roleId,
    entityId: $entityId
    entityType: $entityType,
    currentRoleId: $currentRoleId,
    inherit: $inherit
  )
}`;

export const createDocument = `mutation CreateDocument($title: String!,
    $description: String = "",
    $documentType: DocumentType!,
    $isTemplate: Boolean = false,
    $parentFolderId: String!,
    $parentTemplateId: String,
    $clonePermission: Boolean = false,
    $weighted: Boolean = false,
    $supplementalTag: String = "",
    $isPublic: Boolean,
    $isShared: Boolean,
    $documentId: String = "",
    $translationTag: String = "",
    $translationLabel: String = "",
    ) {
  createDocument(
    title: $title,
    description: $description,
    documentType: $documentType,
    isTemplate: $isTemplate,
    parentFolderId: $parentFolderId,
    parentTemplateId: $parentTemplateId,
    clonePermission: $clonePermission,
    weighted: $weighted,
    supplementalTag: $supplementalTag,
    isPublic: $isPublic,
    isShared: $isShared,
    documentId: $documentId,
    translationTag: $translationTag,
    translationLabel: $translationLabel,
  ) {
    id
  }
  }`;


export const createPreferences = `mutation CreatePreferences($organizationUrl: String!) {
createPreferences(
  organizationUrl: $organizationUrl,
  )
}`;

export const createDocumentSup = `mutation CreateDocument($title: String!,
  $description: String,
  $documentType: DocumentType!,
  $isTemplate: Boolean,
  $parentFolderId: String!,
  $parentTemplateId: String,
  $clonePermission: Boolean,
  $weighted: Boolean,
  $supplementalTag: String,
  $isPublic: Boolean,
  $isShared: Boolean,
  $documentId: String
  ) {
createDocument(
  title: $title,
  description: $description,
  documentType: $documentType,
  isTemplate: $isTemplate,
  parentFolderId: $parentFolderId,
  parentTemplateId: $parentTemplateId,
  clonePermission: $clonePermission,
  weighted: $weighted,
  supplementalTag: $supplementalTag,
  isPublic: $isPublic,
  isShared: $isShared,
  documentId: $documentId
) {
  id
}
}`;

export const createRelationship = `mutation createRelationship($sourceDocId: String!, $destinationDocId: String!, $relationship: String) {
  createRelationship(
    sourceDocId: $sourceDocId,
    destinationDocId: $destinationDocId,
    relationship: $relationship
    )
}`;

export const removeRelationship = `mutation removeRelationship($sourceDocId: String!, $destinationDocId: String!, $relationship: String) {
  removeRelationship(
    sourceDocId: $sourceDocId,
    destinationDocId: $destinationDocId,
    relationship: $relationship
    )
}`;

export const addSectionToDocument = `mutation addSectionToDocument($sectionTitle: String!,
  $sectionContent: AWSJSON!,
  $index: Int,
  $parentId: String!,
  $documentId: String!,
  $targetSectionId: String = "",
  $position: String = "",
  $supplementalTag: String = "",
  $sectionType: String = "",
  $transcludeId: String = "",
  $transcludeVersion: String = "") {
addSectionToDocument(
  sectionTitle: $sectionTitle,
  sectionContent: $sectionContent,
  index: $index,
  parentId: $parentId,
  documentId: $documentId,,
  targetSectionId: $targetSectionId,
  position: $position,
  supplementalTag: $supplementalTag
  sectionType: $sectionType,
  transcludeId: $transcludeId,
  transcludeVersion: $transcludeVersion
) {
  id
  label
  sectionTitle
  sectionContent
  childOrdering
  subSection
}
}`;


export const updateSection = `mutation updateSectionContent($sectionTitle: String!, $sectionContent: AWSJSON!, $parentId: String!, $documentId: String!, $sectionId: String!, $index: Int!, $supplementalTag: String, $targetSectionId: String, $position: String, $translationTag: String) {
  updateSectionContent(
    sectionTitle: $sectionTitle,
    sectionContent: $sectionContent,
    sectionId: $sectionId,
    parentId: $parentId,
    documentId: $documentId,
    index: $index,
    supplementalTag: $supplementalTag,
    targetSectionId: $targetSectionId,
    position: $position,
    translationTag: $translationTag
  ) {
    id
    label
    sectionTitle
    sectionContent
    childOrdering
    subSection
  }
}`;

export const getUploadAttachmentUrl = `mutation getUploadAttachmentUrl($docId: String!, $fileName: String!, $description: String = "", $attachmentType: String!, $isPublic: Boolean = true, $supplementalTag: String){
  getUploadAttachmentUrl(
    docId: $docId,
    fileName: $fileName,
    description: $description,
    attachmentType: $attachmentType,
    isPublic: $isPublic,
    supplementalTag: $supplementalTag
  ) {
    fileId
    url
  }
}`;

export const updateDocumentAttachment = `mutation updateDocumentAttachment($docId: String!, $attachmentId: String!, $description: String = "", $isPublic: Boolean = true){
  updateDocumentAttachment(
    documentId: $docId,
    attachmentId: $attachmentId,
    description: $description,
    isPublic: $isPublic
  )
}`;


export const deleteDocumentAttachment = `mutation deleteDocumentAttachment($documentId: String!, $instanceType:String!, $fileId: String!) {
  deleteDocumentAttachment(
    documentId: $documentId,
    instanceType: $instanceType,
    fileId: $fileId
  )
}`;

export const removeSectionFromDocument = `mutation deleteSectionFromDocument($documentId: String!, $sectionId: String!, $supplementalTag: String, $targetSectionId: String) {
  deleteSectionFromDocument(
    documentId: $documentId,
    sectionId: $sectionId,
    supplementalTag: $supplementalTag,
    targetSectionId: $targetSectionId
    )
}`;

export const addAttributeToDocument = `mutation addAttributeToDocument(
  $docId: String!,
  $attributeName: String!,
  $description: String = "",
  $attributeType: AttributeType!,
  $attributeConfig: String!,
  $isResponseRequired: Boolean!,
  $denyUpdateFieldProperties: Boolean,
  $denyUpdateFieldResponse: Boolean,
  $isPublicResponse: Boolean
) {
  addAttributeToDocument(
    documentId: $docId,
    attributeName: $attributeName,
    description: $description,
    attributeType: $attributeType,
    attributeConfig: $attributeConfig,
    isResponseRequired: $isResponseRequired,
    denyUpdateFieldProperties: $denyUpdateFieldProperties,
    denyUpdateFieldResponse: $denyUpdateFieldResponse,
    isPublicResponse: $isPublicResponse
  )
}`;

export const updateAttributeValue = `mutation updateAttributeValue($docId: String!, $attributeId: String!, $value: String!) {
  updateAttributeValue(
    documentId: $docId,
    attributeId: $attributeId,
    attributeValue: $value
  )
}`;

export const updateAttributeConfig = `mutation updateAttributeConfig(
  $docId: String!,
  $id: String!,
  $attributeName: String,
  $description: String = "",
  $attributeConfig: String,
  $isResponseRequired: Boolean!,
  $denyUpdateFieldResponse: Boolean,
  $denyUpdateFieldProperties: Boolean,
  $isPublicResponse: Boolean
) {
  updateAttributeConfig(
    documentId: $docId,
    attributeId: $id,
    attributeName: $attributeName,
    description: $description,
    attributeConfig: $attributeConfig,
    isResponseRequired: $isResponseRequired,
    denyUpdateFieldResponse: $denyUpdateFieldResponse,
    denyUpdateFieldProperties: $denyUpdateFieldProperties,
    isPublicResponse: $isPublicResponse
  )
}`;

export const removeAttributeFromDocument = `mutation removeAttribute($documentId: String!, $attributeId: String!) {
  removeAttribute(
  documentId: $documentId,
  attributeId: $attributeId
  )
}`;

export const updateDocument = `mutation updateDocument(
    $documentId: String!,
    $documentType: DocumentType!,
    $isTemplate: Boolean!,
    $parentFolderId: String!,
    $title: String!,
    $description: String,
    $isPublic: Boolean,
    $isShared: Boolean,
    $expiration: String,
    $supplementalTag: String,
    $translationTag: String) {
  updateDocument(
    documentId: $documentId,
    documentType: $documentType,
    isTemplate: $isTemplate,
    parentFolderId: $parentFolderId,
    title: $title,
    description: $description,
    isPublic: $isPublic,
    isShared: $isShared,
    expiration: $expiration,
    supplementalTag: $supplementalTag,
    translationTag: $translationTag
  )
}`;

export const publishDocument = `mutation publishDocument(
    $documentId: String!,
    $revisionComments: String!,
    $dependentDocuments: [String],
    $subsequentPublicationType: String,
    $bulkTemplateId: String,
    $translationTag: String,
    $supplementalTag: String) {
  publishDocument(
    documentId: $documentId,
    revisionComments: $revisionComments,
    dependentDocuments: $dependentDocuments,
    subsequentPublicationType: $subsequentPublicationType,
    bulkTemplateId: $bulkTemplateId,
    translationTag: $translationTag,
    supplementalTag: $supplementalTag
  )
}`;

/*
Process GraphQL Section
*/
export const createProcessTemplate = `mutation createWorkflowTemplate(
  $entityId: String!,
  $entityType: EntityType!,
  $template: AWSJSON!
) {
  createWorkflowTemplate(
    entityId : $entityId,
    entityType : $entityType,
    template : $template
  )
}
`;

export const setWorkflowTemplateDefault = `mutation setWorkflowTemplateDefault($entityId: String!, $templateId: String!, $entityType: EntityType!, $isBulkDefault: Boolean = false) {
  setWorkflowTemplateDefault(entityId: $entityId, entityType: $entityType, templateId: $templateId, isBulkDefault: $isBulkDefault)
}
`;

export const setWorkflowTemplateBulkDefault = `mutation setWorkflowTemplateBulkDefault($entityId: String!, $templateId: String!, $entityType: EntityType!) {
  setWorkflowTemplateBulkDefault(entityId: $entityId, entityType: $entityType, templateId: $templateId)
}
`;

export const queryDocuments = `mutation queryDocuments($searchQuery: DocumentSearchQuery) {
  queryDocuments(searchQuery : $searchQuery,)
}`
  ;

export const processWorkflowEvent = `mutation processWorkflowEvent(
  $entityType: String!,
  $entityId: String!,
  $templateId: String!
  $eventType: String!
  $workflowId: String
  $comment: String
) {
  processWorkflowEvent(
    entityType: $entityType,
    entityId: $entityId,
    templateId: $templateId,
    eventType: $eventType,
    workflowId: $workflowId,
    comment: $comment
  )
}
`;

export const transclusionBulkApprove = `mutation transclusionBulkApprove(
  $entityType: String!,
  $entityId: String!,
  $eventType: String!,
  $workflowInstanceIds: [String],
  $dependentDocumentIds: [String],
  $comments: String
) {
  transclusionBulkApprove(
    entityType: $entityType,
    entityId: $entityId,
    eventType: $eventType,
    workflowInstanceIds: $workflowInstanceIds,
    dependentDocumentIds: $dependentDocumentIds,
    comments: $comments
  )
}
`;

export const updateMigratedDocumentAcknowledgement = `mutation updateMigratedDocumentAcknowledgement(
  $documentId : String!,
  $ownerAlias: String!,
  $fullyMigrated: Boolean!,
  $message: String!,
  $title: String!,
  $grcId: String!
){
  updateMigratedDocumentAcknowledgement(
    documentId: $documentId,
    ownerAlias: $ownerAlias,
    fullyMigrated: $fullyMigrated,
    message: $message,
    title: $title,
    grcId: $grcId
  )
}`;

export const updatePreMigrationDocumentApproval = `mutation updatePreMigrationDocumentApproval(
  $grcId: String!,
  $ownerAlias: String!,
  $title: String!,
  $status: String!,
  $parentFolder: String!,
  $parentFolderId: String!,
  $grcDocumentType: String!,
  $policyDocumentType: String!,
  $comment: String!,
  $approvedBy: String!
){
  updatePreMigrationDocumentApproval(
    grcId: $grcId,
    ownerAlias: $ownerAlias,
    title: $title,
    status: $status,
    parentFolder: $parentFolder,
    parentFolderId: $parentFolderId,
    grcDocumentType: $grcDocumentType,
    policyDocumentType: $policyDocumentType,
    comment: $comment,
    approvedBy: $approvedBy
  )
}`;

export const requestException = `mutation requestException(
  $title: String!,
  $description: String,
  $parentFolderId: String!,
  $policyId: String!,
  $sectionIds: [String]!,
  $supplementalTag: String,
  $translationTag: String
) {
  requestException(
    title: $title,
    description: $description,
    parentFolderId: $parentFolderId,
    policyId: $policyId,
    sectionIds: $sectionIds,
    supplementalTag: $supplementalTag,
    translationTag: $translationTag
  ) {
    id
  }
}`;

export const requestExceptionExtension = `mutation requestExceptionExtension(
  $title: String!,
  $description: String,
  $parentFolderId: String!,
  $documentId: String!,
  $action: String!,
) {
  requestExceptionExtension(
    title: $title,
    description: $description,
    parentFolderId: $parentFolderId,
    documentId: $documentId,
    action: $action,
  ) {
    id
  }
}`;

export const addSubscriber = `mutation addSubscriber($documentId_eventType: String!, $subscriber: [String]!, $subscribe: [String]!, $destination: String!, $subscriberType: [String]!) {
  addSubscriber(
    documentId_eventType: $documentId_eventType,
    subscriber: $subscriber,
    subscribe: $subscribe,
    destination: $destination,
    subscriberType: $subscriberType
  )
}`;

export const updateWorkflowInstance = `mutation updateWorkflowInstance($workflow: AWSJSON!) {
  updateWorkflowInstance(workflow: $workflow)
} `;

export const addComment = `mutation addComment($documentId: String!, $content: String!, $instanceType: InstanceType!, $revisionId: Int, $isReply: Boolean, $parentCommentId: String, $documentSecondaryId: String) {
  addComment(documentId: $documentId, content: $content, instanceType: $instanceType, revisionId: $revisionId, isReply: $isReply, parentCommentId: $parentCommentId, documentSecondaryId: $documentSecondaryId ) {
    addedBy
    createdAt
    updatedAt
    content
    commentId
    isParent
    revisionId
    instanceType
  }
} `;

export const editComment = `mutation editComment($documentId: String!, $content: String!, $instanceType: InstanceType!, $commentId: String!, $documentSecondaryId: String ) {
  editComment(documentId: $documentId, content: $content, instanceType: $instanceType, commentId: $commentId, documentSecondaryId: $documentSecondaryId ) {
    addedBy
    createdAt
    updatedAt
    content
    commentId
    isParent
    revisionId
    instanceType
  }
} `;

export const deleteComment = `mutation deleteComment($documentId: String!, $commentId: String!) {
  deleteComment(
    documentId: $documentId,
    commentId: $commentId
    )
} `;


export const createAuditLogs = `mutation createAuditLogs($docId: String!, $action: String!, $context: String) {
  createAuditLogs(
    docId: $docId,
    action: $action
    context: $context
    )
} `;

export const updateTags = `mutation updateTags($docId: String!, $tagNames: [String]!, $isAdded: [Boolean]!) {
  updateTags(
    docId: $docId,
    tagNames: $tagNames,
    isAdded: $isAdded
  )
}`;

/**
 * Allows a user to acknowledge a given document.
 */
export const acknowledge = `mutation acknowledge($documentId: String!, $documentVersion: String!, $pendingAcknowledgements: [String]!) {
  acknowledge(documentId: $documentId, documentVersion: $documentVersion, pendingAcknowledgements: $pendingAcknowledgements)
}`;

/**
 * Allows a user with edit permissions to delete an existing acknowledgment process/template on a document.
 */
export const deleteAcknowledgmentProcess = `mutation deleteAcknowledgementProcess($documentId: String!, $templateId: String!) {
  deleteAcknowledgementProcess(documentId: $documentId, templateId: $templateId)
}`;

/**
 * Allows a user with edit permissions to create a new or update an existing acknowledgment process/template.
 * Passing in a templateId specifies that an existing process is being updated.
 */
export const updateAcknowledgmentProcess = `mutation updateAcknowledgementProcess(
  $templateName: String!,
  $templateId: String,
  $documentId: String!,
  $recurringDate: String!,
  $gracePeriod: Int!,
  $entities: [AcknowledgementEntity]!,
  $message: String,
  $startDate: String!,
  $endDate: String,
  $enabled: Boolean
) {
  updateAcknowledgementProcess(
    templateName: $templateName,
    templateId: $templateId,
    documentId: $documentId,
    recurringDate: $recurringDate,
    gracePeriod: $gracePeriod,
    entities: $entities,
    message: $message,
    startDate: $startDate,
    endDate: $endDate,
    enabled: $enabled
  )
}`;

export const updateExceptionExclusions = `mutation updateExceptionExclusions(
  $documentId: String!,
  $excludedSectionIds: [String]!
) {
  updateExceptionExclusions(
    documentId: $documentId,
    excludedSectionIds: $excludedSectionIds
  )
}`;

export const attachExceptionForms = `mutation attachExceptionForms(
  $documentId: String!,
  $intakeForm: String,
  $extensionForm: String,
  $closureForm: String
) {
  attachExceptionForms(
    documentId: $documentId,
    intakeForm: $intakeForm
    extensionForm: $extensionForm
    closureForm: $closureForm
  )
}`;

export const createTemplateWorkflowMappingV1 = `mutation createTemplateWorkflowMappingV1($mappings: [TemplateWorkflowMappingV1]!) {
  createTemplateWorkflowMappingV1(mappings: $mappings)
}`;

export const deleteTemplateWorkflowMappingV1 = `mutation deleteTemplateWorkflowMappingV1($mappings: [TemplateWorkflowMappingV1]!) {
  deleteTemplateWorkflowMappingV1(mappings: $mappings)
}`;

export const createTemplateWorkflowMapping = `mutation createTemplateWorkflowMapping($mappings: [TemplateWorkflowMapping]!) {
  createTemplateWorkflowMapping(mappings: $mappings)
}`;

export const enableException = `mutation enableException($documentId: String!, $enable: Boolean!) {
  enableException(documentId: $documentId, enable: $enable)
}`;

export const submitFeedback = `mutation submitFeedback($rating: Int!, $comments: String, $location: String) {
  submitFeedback(rating: $rating, comments: $comments, location: $location)
}`;

export const updateReviewSchedule = `mutation updateReviewSchedule(
  $entityType: String!,
  $entityId: String!,
  $startDate: String!,
  $endDate: String!,
  $createdOn: String!,
  $cronExp: String!,
  $review: ScheduledReviewWorkflow!
) {
  updateReviewSchedule(
    entityType: $entityType,
    entityId: $entityId,
    startDate: $startDate,
    endDate: $endDate,
    createdOn: $createdOn,
    cronExp: $cronExp,
    review: $review
  )
}`;

export const createReviewSchedule = `mutation createReviewSchedule(
  $entityType: String!,
  $entityId: String!,
  $startDate: String!,
  $endDate: String!,
  $createdOn: String!,
  $cronExp: String!,
  $review: ScheduledReviewWorkflow!
) {
  createReviewSchedule(
    entityType: $entityType,
    entityId: $entityId,
    startDate: $startDate,
    endDate: $endDate,
    createdOn: $createdOn,
    cronExp: $cronExp,
    review: $review
  )
}`;

export const deleteReviewSchedule = `mutation deleteReviewSchedule($entityType: String!, $entityId: String!) {
  deleteReviewSchedule(entityType: $entityType, entityId: $entityId)
}`;

export const completeScheduledReview = `mutation completeScheduledReview($entityType: String!, $entityId: String!, $workflowInstanceId: String!) {
  completeScheduledReview(entityType: $entityType, entityId: $entityId, workflowInstanceId: $workflowInstanceId)
}`;

export const deleteExceptionRequest = `mutation deleteExceptionRequest($documentId: String!, $documentType: DocumentType!) {
  deleteExceptionRequest(documentId: $documentId, documentType: $documentType)
}`;

export const updateFavorites = `mutation updateFavorites($entityId: String!, $entityType: String!) {
  updateFavorites(entityId: $entityId, entityType: $entityType)
}`;

export const deleteFavorites = `mutation deleteFavorites($entityId: String!, $entityType: String!) {
  deleteFavorites(entityId: $entityId, entityType: $entityType)
}`;

export const updateFormula = `mutation updateFormula($entityId: String!, $formulas: [RiskFormula]) {
  updateFormula(entityId: $entityId, formulas: $formulas)
}`;

export const overrideFormula = `mutation overrideFormula($entityId: String!, $formulas: [RiskFormula], $reason: String) {
  overrideFormula(entityId: $entityId, formulas: $formulas, reason: $reason)
}`;

export const addFormula = `mutation addFormula($entityId: String!, $formulas: [RiskFormula]) {
  addFormula(entityId: $entityId, formulas: $formulas)
}`;

export const deleteFormula = `mutation deleteFormula($entityId: String!, $formula: RiskFormula) {
  deleteFormula(entityId: $entityId, formula: $formula)
}`;

export const addRiskMatrix = `mutation addRiskMatrix($matrix: RiskMatrix!) {
  addRiskMatrix(matrix: $matrix)
}`;

export const updateRiskMatrix = `mutation updateRiskMatrix($matrixId: String!, $matrix: RiskMatrix!) {
  updateRiskMatrix(matrixId: $matrixId, matrix: $matrix)
}`;

export const deleteRiskMatrix = `mutation deleteRiskMatrix($matrixId: String!) {
  deleteRiskMatrix(matrixId: $matrixId)
}`;

export const updateResponseAnswer = `mutation updateResponseAnswer($entityId: String!, $answerId: String!, $answer: RiskRankAnswerModel!) {
  updateResponseAnswer(entityId: $entityId, answerId: $answerId, answer: $answer)
}`;

export const mapRiskMatrix = `mutation mapRiskMatrix($entityId: String!, $template: RiskRankTemplateMapping!) {
  mapRiskMatrix(entityId: $entityId, template: $template)
}`;

export const addQuestionToForm = `mutation addQuestionToForm($question: QuestionModel!) {
  addQuestionToForm(question: $question)
}`;

export const removeQuestionFromForm = `mutation removeQuestionFromForm($templateId: String!, $formId: String!, $questionId: String!) {
  removeQuestionFromForm(templateId: $templateId, formId: $formId, questionId: $questionId)
}`;

export const addRiskForm = `mutation addRiskForm($form: RiskFormModel!) {
  addRiskForm(form: $form)
}`;

export const updateRiskForm = `mutation updateRiskForm($formId: String!, $form: RiskFormModel!) {
  updateRiskForm(formId: $formId, form: $form)
}`;

export const removeRiskForm = `mutation removeRiskForm($templateId: String!, $formId: String!) {
  removeRiskForm(templateId: $templateId, formId: $formId)
}`;

export const updateQuestionToForm = `mutation updateQuestionToForm($question: QuestionUpdateModel!) {
  updateQuestionToForm(question: $question)
}`;

export const reorderRiskForm = `mutation reorderRiskForm($entityId: String!, $formId: String!, $entityType: String!, $questionId: String, $oldSortOrder: Int!, $newSortOrder: Int!) {
  reorderRiskForm(entityId: $entityId, formId: $formId, entityType: $entityType, questionId: $questionId, oldSortOrder: $oldSortOrder, newSortOrder: $newSortOrder)
}`;

export const reorderSupplementalSections = `mutation reorderSupplementalSections($documentId: String!, $supplementalTag: String!, $reorderedItems: String!) {
  reorderSupplementalSections(documentId: $documentId, supplementalTag: $supplementalTag, reorderedItems: $reorderedItems)
}`;

export const getPdf = `mutation getPdf($entityId: String!, $versionId: String, $instanceType: String!, $selectedSections: [String]!, $supplementalTag: String, $translationTag: String) {
  getPdf(entityId: $entityId, versionId: $versionId, instanceType: $instanceType, selectedSections: $selectedSections, supplementalTag: $supplementalTag, translationTag: $translationTag)
}`;

export const batchActions = `mutation batchActions(
  $entityType: String!,
  $entityId: [String]!,
  $updateAction: String!,
  $includeSubfolders: Boolean!,
  $includeSubfolderDocuments: Boolean!,
  $updateValue: BulkActionsValues!
  ) {
  batchActions(
    entityType: $entityType,
    entityId: $entityId,
    updateAction: $updateAction,
    includeSubfolders: $includeSubfolders,
    includeSubfolderDocuments: $includeSubfolderDocuments,
    updateValue: $updateValue
    )
}`;

export const requestPermission = `mutation requestPermission($entityId: String!, $entityType: String!, $accessRequested: String!, $reason: String) {
  requestPermission(entityId: $entityId, entityType: $entityType, accessRequested: $accessRequested, reason: $reason)
}`;

export const manageSecondaryParents = `mutation manageSecondaryParents($secondaryParentAddList: [String], $secondaryParentRemoveList: [String], $documentId: String!) {
  manageSecondaryParents(
    secondaryParentAddList: $secondaryParentAddList,
    secondaryParentRemoveList: $secondaryParentRemoveList,
    documentId: $documentId
  )
}`;
export const createRelationshipRS = `mutation createRelationshipRS(
    $clientId: String!,
    $entityType: String!,
    $entityId: String!,
    $documents: CreateRelationshipPayload
  ) {
    createRelationshipRS(
      clientId: $clientId,
      entityType: $entityType,
      entityId: $entityId,
      documents: $documents
    )
  }`;


export const deleteRelationshipRS = `mutation deleteRelationshipRS(
    $clientId: String!,
    $entityType: String!,
    $entityId: String!,
    $documents: DeleteRelationshipPayload
  ) {
    deleteRelationshipRS(
      clientId: $clientId,
      entityType: $entityType,
      entityId: $entityId,
      documents: $documents
    )
  }`;

export const updateRelationshipRS = `mutation updateRelationshipRS(
    $clientId: String!,
    $entityType: String!,
    $entityId: String!,
    $documents: UpdateRelationshipPayload
  ) {
    updateRelationshipRS(
      clientId: $clientId,
      entityType: $entityType,
      entityId: $entityId,
      documents: $documents
    )
  }`;


export const updateRelationshipReordering = `mutation updateRelationshipReordering(
    $entityId: String!,
    $entityType: String!,
    $clientId: String!,
    $relationshipType: String!,
    $ordering: [String]!
  ) {
    updateRelationshipReordering(
      entityId: $entityId,
      entityType: $entityType,
      clientId: $clientId
      relationshipType: $relationshipType
      ordering: $ordering
    )
  }`;

export const createUserPermission = `mutation createUserPermission($entityId: String!, $entityType: EntityType!, $roleName: String!, $userToAdd: String!, $roleEntityId: String, $roleEntityType: EntityType, $isInheritable: Boolean, $isPrimaryOwner: Boolean, $isSecondaryOwner: Boolean) {
  createUserPermission(
    entityId: $entityId,
    entityType: $entityType,
    roleName: $roleName
    userToAdd: $userToAdd,
    roleEntityId: $roleEntityId,
    roleEntityType: $roleEntityType,
    isInheritable: $isInheritable,
    isPrimaryOwner: $isPrimaryOwner,
    isSecondaryOwner: $isSecondaryOwner
  )
}`;

export const updateUserPermission = `mutation updateUserPermission($entityId: String!, $entityType: EntityType!, $roleName: String!, $userToAdd: String!, $roleEntityId: String, $roleEntityType: EntityType, $isInheritable: Boolean, $isPrimaryOwner: Boolean, $isSecondaryOwner: Boolean) {
  updateUserPermission(
    entityId: $entityId,
    entityType: $entityType,
    roleName: $roleName
    userToAdd: $userToAdd,
    roleEntityId: $roleEntityId,
    roleEntityType: $roleEntityType,
    isInheritable: $isInheritable,
    isPrimaryOwner: $isPrimaryOwner,
    isSecondaryOwner: $isSecondaryOwner
  )
}`;

export const deleteUserPermission = `mutation deleteUserPermission($entityId: String!, $entityType: EntityType!, $roleName: String!, $userToAdd: String!, $roleEntityId: String, $roleEntityType: EntityType) {
  deleteUserPermission(
    entityId: $entityId,
    entityType: $entityType,
    roleName: $roleName
    userToAdd: $userToAdd,
    roleEntityId: $roleEntityId,
    roleEntityType: $roleEntityType
  )
}`;

export const createCustomRole = `mutation createCustomRole($entityId: String!, $entityType: EntityType!, $roleName: String!, $actions: [String]!, $description: String!) {
  createCustomRole(
    entityId: $entityId,
    entityType: $entityType,
    roleName: $roleName,
    actions: $actions,
    description: $description
  )
}`;

export const updateCustomRole = `mutation updateCustomRole($entityId: String!, $entityType: EntityType!, $roleName: String!, $actions: [String]!, $description: String!) {
  updateCustomRole(
    entityId: $entityId,
    entityType: $entityType,
    roleName: $roleName,
    actions: $actions,
    description: $description
  )
}`;

export const deleteCustomRole = `mutation deleteCustomRole($entityId: String!, $entityType: EntityType!, $roleName: String!) {
  deleteCustomRole(
    entityId: $entityId,
    entityType: $entityType,
    roleName: $roleName,
  )
}`;

export const entityRoleSwitch = `mutation entityRoleSwitch($entityId: String!, $entityType: EntityType!, $fromRole: String!, $fromRoleEntityId: String!, $fromRoleEntityType: EntityType!, $toRole: String!, $toRoleEntityId: String!, $toRoleEntityType: EntityType!) {
  entityRoleSwitch(
    entityId: $entityId,
    entityType: $entityType,
    fromRole: $fromRole,
    fromRoleEntityId: $fromRoleEntityId,
    fromRoleEntityType: $fromRoleEntityType,
    toRole: $toRole,
    toRoleEntityId: $toRoleEntityId,
    toRoleEntityType: $toRoleEntityType,
  )
}`;

export const createNotificationTemplateMapping = `mutation createNotificationTemplateMapping($entityId: String!, $templateId: String!) {
  createNotificationTemplateMapping(
      entityId: $entityId,
      templateId: $templateId
  )
  }`;

export const createRules = `mutation createRules($entityList: [RuleEntity]!,
  $requestType: [String]!,
  $fieldType: String!,
  $fieldValue: [String]!,
  $operator: String,
  $precedence: Int,
  $actionId: String!,
  $recertificationTime: Int) {
    createRules(
        entityList: $entityList,
        requestType: $requestType,
        fieldType: $fieldType,
        fieldValue: $fieldValue,
        operator: $operator,
        precedence: $precedence,
        actionId: $actionId,
        recertificationTime: $recertificationTime,
    )
  }`;

export const updateRules = `mutation updateRules($ruleId: String!,
  $entityList: [RuleEntity]!,
  $requestType: [String]!,
  $fieldType: String!,
  $fieldValue: [String]!,
  $operator: String,
  $precedence: Int,
  $actionId: String!,
  $recertificationTime: Int
  ) {
    updateRules(
        ruleId: $ruleId,
        entityList: $entityList,
        requestType: $requestType,
        fieldType: $fieldType,
        fieldValue: $fieldValue,
        operator: $operator,
        precedence: $precedence,
        actionId: $actionId,
        recertificationTime: $recertificationTime,
    )
  }`;

export const deleteRules = `mutation deleteRules($ruleId: String!) {
  deleteRules(
      ruleId: $ruleId
    )
  }`;

export const getEntityWorkflowRules = `mutation getEntityWorkflowRules($entityId: String!,
  $entityType: String!,
  $ruleId: String,
  $title: String,
  $pageNo: String,
  $pageSize: String) {
    getEntityWorkflowRules(
        entityId: $entityId,
        entityType: $entityType,
        ruleId: $ruleId,
        title: $title,
        pageNo: $pageNo,
        pageSize: $pageSize
    )
  }`;
